<template>
  <div class="terms">
    <div class="legalMenu">
      <ul>
        <li @click="handleTerms(1)">
          <a :class="{ primary: status == 1 }">이용 약관</a>
        </li>
        <li @click="handleTerms(2)">
          <a :class="{ primary: status == 2 }">개인정보 수집 및 이용 약관</a>
        </li>
        <!-- <li @click="handleTerms(3)">
          <a :class="{ primary: status == 3 }">제 3자에 대한 개인 정보 제공</a>
        </li> -->
      </ul>
    </div>
    <UseTerms v-if="status == 1" />
    <TermsPrivacy v-else-if="status == 2" />
    <TermsProvide v-else />
  </div>
</template> 
<script>
import UseTerms from "@/components/terms/UseTerms";
import TermsPrivacy from "@/components/terms/TermsPrivacy";
import TermsProvide from "@/components/terms/TermsProvide";
import { mapState } from "vuex";
export default {
  components: {
    UseTerms,
    TermsPrivacy,
    TermsProvide,
  },
  data() {
    return {
      status: 1,
    };
  },
  computed: {
    ...mapState(["navVisible"]),
  },
  mounted() {
    this.setNavbar(false);
  },
  methods: {
    handleTerms(status) {
      this.status = status;
    },
    setNavbar(type) {
      let data = {
        visible: type,
      };
      this.$store.dispatch("SET_NAVBAR", data);
    },
  },
};
</script>